import { useTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./scenes/layout";
import Home from "./scenes/home";
import About from "./scenes/about";
import Estates from "./scenes/estates";
import Contact from "./scenes/contact";
import Property from "./scenes/property";

function App() {
  const theme = useTheme();

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/estates" element={<Estates />} />
              <Route path="/property" element={<Property />} />
              <Route path="/contact" element={<Contact />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
