import React from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";
import Map from "./map";
import useMediaQuery from "@mui/material/useMediaQuery";


const TitleBlock = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Container
      sx={{
        py: "2rem",
      }}>
      <Grid
        container
        spacing={4}
        sx={{
          p: isNonMobile ? "5rem" : "1rem",
        }}>
        <Grid item md={6}>
          <Typography
            variant="textBlockHeader"
            sx={{ color: theme.palette.primary }}>
            Our Office
          </Typography>
          <Typography>
            Our office is located on the ground level at 16 Yeates Avenue,
            Boonah.
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Map />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TitleBlock;
