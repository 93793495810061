import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import Subtitle from "./Subtitle";

// collect images for use in slider
import OldHutCarneysCk from "../../../assets/OldHutCarneysCk.jpg";
import irrigationPlume from "../../../assets/irrigationPlume.jpg";
import customsHouse from "../../../assets/CustomsHouse.jpg";

export default function TitleSlider() {
  return (
    <HeroSlider
      height={"90vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 200,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide),
      }}>
      <Overlay>
        <Wrapper>
          <Title>Property</Title>
          <Subtitle>
            We have been helping clients to buy, sell, and lease property in the
            local region and around Australia for three decades.
          </Subtitle>
        </Wrapper>
      </Overlay>

      <Slide
        label="Old Hut - Carney's Creek"
        background={{
          backgroundImageSrc: OldHutCarneysCk,
        }}
      />

      <Slide
        label="Customs House - Brisbane"
        background={{
          backgroundImageSrc: customsHouse,
        }}
      />

      <Slide
        label="Irrigation Plume - Teviotville"
        background={{
          backgroundImageSrc: irrigationPlume,
        }}
      />

      <MenuNav />
    </HeroSlider>
  );
}
