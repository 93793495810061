import React from "react";
import TitleBlock from "./titleBlock";
import ColouredTextStrip from "../../components/ColouredTextStrip";
import { Box } from "@mui/material";
import OurPeople from "../../components/OurPeople";

const About = () => {
  return (
    <Box>
      <TitleBlock />
      <ColouredTextStrip
        title="Our people"
        text="The people who make up Boylson & Collyer all live locally and have a deep commitment to the local area and the community, as well as extensive experience in the areas of law that matter to locals."
      />
      <OurPeople />
    </Box>
  );
};

export default About;
