import React from "react";
import { Box } from "@mui/material";
import TitleSlider from "./TitleSlider";
import TitleBlock from "./titleBlock";
import Map from "./map";

const Contact = () => {
  return (
    <Box>
      <TitleSlider />
      <TitleBlock />
    </Box>
  );
};

export default Contact;
