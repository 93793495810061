import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useMediaQuery from "@mui/material/useMediaQuery";

const DraftingList = [
  "The assessment of assets",
  "The assessment of likely taxation",
  "Advice regarding the possibility of claims against the estate by third parties",
  "The protection of assets",
];

const EvaluatingList = [
  "Newly married couples or those divorcing",
  "A change to the family's asset pool",
  "A change in working conditions such as the buying or selling of a business",
  "Changes to superannuation, insurance policies or taxation levels",
  "The establishment of discretionary trusts",
];

const Planning = () => {
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Box
      sx={{
        p: isNonMobile ? "5rem" : "1rem",
      }}>
      <Typography variant="h2">Planning for the future</Typography>
      <Typography>
        Estate planning goes beyond drafting a will - it includes:
      </Typography>
      <List>
        {DraftingList.map((text, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Typography>
        Estate planning is an active process of re-evaluating the estate when
        circumstances in life change, such as:
      </Typography>
      <List>
        {EvaluatingList.map((text, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Typography>
        A successful plan is made involving all family members as it will take
        into account not only provisions for your retirement income but also the
        plans, aptitudes and existing assets of younger generations.
      </Typography>
    </Box>
  );
};

export default Planning;
