import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

// retrieve images
import lostHouse from "../assets/losthouse.jpg";
import highstreet from "../assets/highstreet.jpg";
import roads from "../assets/roads.jpg";

export default function ImageCard() {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        px: "1rem",
      }}>
      <Grid item md={4}>
        <Card>
          <CardMedia
            component="img"
            width="100%"
            image={lostHouse}
            alt="a lost house"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              align="center">
              Property
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              Buying, selling and leasing property, whether residential,
              commercial or industrial.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card>
          <CardMedia
            component="img"
            width="100%"
            image={highstreet}
            alt="high street of Boonah"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              align="center">
              Power of Attorney
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              Establish a power of attorney for yourself or a loved one.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={4}>
        <Card>
          <CardMedia
            component="img"
            width="100%"
            image={roads}
            alt="local roads"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              align="center">
              Wills and estate planning
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              Wills and powers of attorney for yourself or a loved one.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
