import React from "react";
import { Box } from "@mui/material";
import TitleSlider from "./TitleSlider";
import TitleBlock from "./titleBlock";
import ColouredTextStrip from "../../components/ColouredTextStrip";
import AccordionBlock from "../../components/AccordionBlock";

const propertyGuidelines = [
  {
    title: "Buying property",
    text: "Buying a home or property is one of the most stressful and expensive things you will ever do. It's vital that you seek legal advice from someone experienced in property law and who knows the area.",
  },
  {
    title: "Contracts",
    text: "Please ask us to review the contract and explain the terms before you sign. There may be several things that can be negotiated in your favour, and you need to be absolutely clear on your rights and responsibilities before signing. If you are happy to proceed, you can make an offer by signing the contract. You must also pay a deposit (often $1,000 or $5,000 or $10,000). The deposit is held in Trust by the agent or a solicitor and is refunded if the contract is not signed by both parties or if it later terminates. The deposit is held by the real estate agent or the solicitor and is released to the seller after the property is settled. The agent can take other offers so the contract is not binding until both Buyer and Seller sign.",
  },
  {
    title: "Offers",
    text: "If your offer is accepted then either you or the agent will give the fully signed contract to us. We will immediately write to you with all the information you need to proceed and also seeking details we need to know such as your ID and whether you are a first home buyer.",
  },
  {
    title: "Selling property",
    text: "Once you've made the decision to sell your property one of the first tasks is to have the Contract for Sale drawn up. The contract will include not only the Schedule, Special Conditions and Standard Terms of sale, but will also specify what items are included or excluded from the sale, and any other special requirements you might have like a longer or shorter settlement than usual, or perhaps that the contract be conditional upon another contract for you to buy your next property. There are a lot of ways that a contract can be varied, so it's important to discuss your situation in detail with us before you sign.",
  },
  {
    title: "Mortgages on properties for sale",
    text: "If you have a mortgage over the property you are selling, you must contact your bank immediately to arrange to have the mortgage released at settlement. The bank must post documents to you, often from their central offices in Sydney and Melbourne, and you must sign and return them. This may take 2-3 weeks or longer.",
  },
  {
    title: "Settlement",
    text: "Settlement (the day that the money is exchanged for the property and keys handed over) is scheduled in accordance with the contract. The contract will specify when and where settlement takes place. During the wait for settlement we will liaise with your bank in relation to releasing any mortgage held on the property. Shortly before settlement, you should advise electricity, water and other suppliers of the sale.",
  },
  {
    title: "Retail and commercial leases",
    text: "We can provide assistance with retail and commercial leases and property and business purchases. It is important to seek legal advice from an experienced commercial lawyer before entering a lease or contract, as it has the potential of becoming a negative impact on your business' cash flow at a later stage.",
  },
  {
    title: "Retail and commercial lease assistance",
    text: "We can assist you with drafting and reviewing retail and commercial leases; providing advice on the Queensland Retail Shop Leases Act; negotiation for the terms of the lease (including rent, transfer options, duration, exit and renewal options); subletting of premises; advice and assistance identifying the most suitable business structure; possible dispute resolution",
  },
];

const Contact = () => {
  return (
    <Box>
      <TitleSlider />
      <TitleBlock />
      <ColouredTextStrip
        title="Buying, selling and leasing property"
        text="Buying or selling a residential, farming or commercial property is a big decision and a significant matter for most people. Boylson & Collyer offer many years of experience with property law in Queensland and in particular in the Scenic Rim and Fassifern. We have represented both buyers and sellers of property for all legal matters. This brings peace of mind to our clients."
      />
      <AccordionBlock
        title="Buying and selling guidelines"
        accordionItems={propertyGuidelines}
      />
    </Box>
  );
};

export default Contact;
