import React from "react";
import { Grid, Typography, useTheme, CardMedia } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import kalbarLots from "../../assets/kalbarLots.png";

const TitleBlock = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Grid
      container
      spacing={4}
      sx={{
        p: isNonMobile ? "5rem" : "1rem",
      }}>
      <Grid item md={6}>
        <Typography
          align="center"
          variant="h1"
          sx={{ color: theme.palette.primary }}>
          We settle online
        </Typography>
        <Typography
          align="justify"
          sx={{
            py: "1rem",
          }}>
          Boylson and Collyer is fully PEXA registered, meaning that we can do
          property settlements online.
        </Typography>
        <Typography align="justify">
          Traditionally, property settlements required the physical presence of
          each party. With PEXA, buying and selling property is much more
          convenient and provides greater certainty.
        </Typography>
      </Grid>
      <Grid item md={6}>
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            borderRadius: "25px",
          }}
          image={kalbarLots}
          alt="Kalbar lots"
        />
      </Grid>
    </Grid>
  );
};

export default TitleBlock;
