import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import Subtitle from "./Subtitle";

// collect images for use in slider
import CunnGap from "../../assets/CunnGap.jpg";
import kalbar from "../../assets/kalbar.jpg";
import machinery from "../../assets/machinery.jpg";
import dugie from "../../assets/dugie.jpg";

export default function TitleSlider() {
  return (
    <HeroSlider
      height={"90vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 300,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide),
      }}>
      <Overlay>
        <Wrapper>
          <Title>Boylson & Collyer</Title>
          <Subtitle>
            Specialising in legal advice for the local community.
          </Subtitle>
        </Wrapper>
      </Overlay>

      <Slide
        // shouldRenderMask
        label="Cunningham's Gap - Kalbar"
        background={{
          backgroundImageSrc: CunnGap,
        }}
      />

      <Slide
        // shouldRenderMask
        label="Dugandan Hotel - Boonah"
        background={{
          backgroundImageSrc: dugie,
        }}
      />

      <Slide
        // shouldRenderMask
        label="Farmland - Fassifern"
        background={{
          backgroundImageSrc: machinery,
        }}
      />

      <Slide
        // shouldRenderMask
        label="Mt French - Kalbar"
        background={{
          backgroundImageSrc: kalbar,
        }}
      />
      <MenuNav />
    </HeroSlider>
  );
}
