import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const ColouredTextStrip = ({ title, text }) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");

  return (
    <Box
      sx={{
        py: isNonMobile ? "5rem" : "1rem",
        my: "5rem",
        backgroundColor: "primary.main",
      }}>
      <Typography
        variant="h1"
        align="center"
        sx={{ py: "2rem", color: theme.palette.grey[100] }}>
        {title}
      </Typography>
      <Typography
        align="center"
        sx={{
          px: "5rem",
          color: theme.palette.grey[300],
        }}>
        {text}
      </Typography>
    </Box>
  );
};

export default ColouredTextStrip;
