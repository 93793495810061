import { createTheme } from "@mui/material";

// Taken from here: https://www.canva.com/colors/color-palettes/mermaid-lagoon/

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0C2D48",
    },
    secondary: {
      main: "#145DA0",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 44,
    },
    h2: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 36,
    },
    h3: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 30,
    },
    h4: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 24,
    },
    h5: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 18,
    },
    h6: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
    },
    navHeader: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 22,
      fontWeight: "bold",
    },
    textBlockHeader: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 54,
      fontWeight: "bold",
    },
  },
});
