import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import Subtitle from "./Subtitle";

// collect images for use in slider
import irrigation from "../../../assets/irrigation.jpg";
import templin from "../../../assets/templin.jpg";
import oneTree from "../../../assets/OneTreeRoadvale.jpg";

export default function TitleSlider() {
  return (
    <HeroSlider
      height={"90vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 200,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide),
      }}>
      <Overlay>
        <Wrapper>
          <Title>Contact Us</Title>
          <Subtitle>(07) 5463 1611</Subtitle>
          <Subtitle>admin@dboylson.com.au</Subtitle>
        </Wrapper>
      </Overlay>

      <Slide
        label="Lone Tree - Roadvale"
        background={{
          backgroundImageSrc: oneTree,
        }}
      />

      <Slide
        label="Irrigation - Fassifern"
        background={{
          backgroundImageSrc: irrigation,
        }}
      />

      <Slide
        label="Sunset - Templin"
        background={{
          backgroundImageSrc: templin,
        }}
      />

      <MenuNav />
    </HeroSlider>
  );
}
