import React from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
  Grid,
  Container,
  Link,
  useTheme,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

// images
import QLSlogo from "../assets/QLS-logo.png";

const usefulLinks = [
  {
    text: "Property Council of Australia",
    to: "https://www.propertycouncil.com.au/",
  },
  {
    text: "Australian Institute of Conveyancers",
    to: "https://aicnational.com.au/",
  },
  {
    text: "Real Estate Institute of Queensland",
    to: "https://www.reiq.com/",
  },
  {
    text: "Titles Queensland",
    to: "https://www.titlesqld.com.au/",
  },
  {
    text: "Money Smart Mortgage Calculator",
    to: "https://moneysmart.gov.au/home-loans/mortgage-calculator",
  },
];

const Footer = () => {
  const theme = useTheme();

  return (
    <footer>
      <Box
        sx={{
          my: "5rem",
          backgroundColor: theme.palette.grey[100],
        }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item md={1}></Grid>
            <Grid item md={7}>
              <CardMedia
                component="img"
                sx={{
                  my: "3rem",
                  width: "60%",
                }}
                image={QLSlogo}
                alt="Logo from the Queensland law society"
              />
              <Typography>
                Boylson and Collyer is a member of the Queensland Law Society.
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h4">Useful links</Typography>
              <List>
                {usefulLinks.map((link, index) => (
                  <ListItem>
                    <Link
                      key={index}
                      href={link.to}
                      variant="body2"
                      rel="noopener"
                      target="_blank">
                      {link.text}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Typography
            align="center"
            sx={{
              my: "1rem",
            }}>
            Photos courtesy Des Mellican.
          </Typography>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
