import React from "react";
import { Typography, useTheme } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import templin from "../../assets/templin.jpg";

const TitleBlock = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Grid
      container
      spacing={4}
      sx={{
        py: isNonMobile ? "5rem" : "3rem",
        px: isNonMobile ? "10rem" : "1rem",
      }}>
      <Grid item md={6}>
        <Typography
          variant="h1"
          align="center"
          sx={{ color: theme.palette.primary }}>
          Estates
        </Typography>
        <Typography align="justify">
          It is important to have a succession plan in place that makes a
          transition easy not only for yourself but also for your family or
          employees and minimises the chances of the business or farm having to
          be sold up when you leave.
        </Typography>
      </Grid>
      <Grid item md={6}>
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            borderRadius: "25px",
          }}
          image={templin}
          alt="Roadway by templin"
        />
      </Grid>
    </Grid>
  );
};

export default TitleBlock;
