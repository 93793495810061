import React from "react";
import { Typography, useTheme } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import fencing from "../assets/fencing.jpg";

const TextBlock = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Grid
      container
      spacing={4}
      sx={{
        px: isNonMobile ? "5rem" : "1rem",
        py: "3rem"
      }}>
      <Grid item md={6}>
        <Typography
          variant="h1"
          align="center"
          sx={{ color: theme.palette.primary }}>
          What we do
        </Typography>
        <Typography
          sx={{
            py: "1rem",
          }}
          variant="h4"
          align="justify"
          fontStyle="italic">
          Boylson and Collyer is a Boonah-based law practice serving the local
          area.
        </Typography>
        <Typography align="justify">
          We provide the community with a wide range of legal services,
          including conveyancing, wills and estate planning.
        </Typography>
        <Typography
          sx={{
            py: "1rem",
          }}>
          Situated on Yeates Avenue, Boonah, we have been part of the local
          community for decades.
        </Typography>
      </Grid>
      <Grid item md={6}>
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            borderRadius: "25px",
          }}
          image={fencing}
          alt="A pretty fence"
        />
      </Grid>
    </Grid>
  );
};

export default TextBlock;
