import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import useMediaQuery from "@mui/material/useMediaQuery";

// images for use
import highStreet from "../../assets/highstreet.jpg";

const TitleBlock = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Grid
      container
      spacing={4}
      sx={{
        px: isNonMobile ? "10rem" : "1rem",
        py: "5rem",
      }}>
      <Grid item md={6}>
        <Typography
          variant="h1"
          align="center"
          sx={{ color: theme.palette.primary }}>
          About Us
        </Typography>
        <Typography align="justify">
          Boylson & Collyer has operated in Boonah for over 30 years and has
          experience in a wide range of law including leasing, town and rural
          conveyancing, wills and estates and commercial law.
        </Typography>
      </Grid>
      <Grid item md={6}>
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            borderRadius: "25px",
          }}
          image={highStreet}
          alt="Live from space album cover"
        />
      </Grid>
    </Grid>
  );
};

export default TitleBlock;
