import React from "react";
import TitleBlock from "./titleBlock";
import Planning from "./Planning";
import ColouredTextStrip from "../../components/ColouredTextStrip";
import { Box } from "@mui/material";
import AccordionBlock from "../../components/AccordionBlock";

const commonQuestions = [
  {
    title: "What is Will and Estate Planning?",
    text: "Planning for your family's future after you're gone is a really important legal task. If you take the time now to make an effective legally binding Will and Testament you can save your family not only stress but money in what will undoubtedly be a difficult time for them. ",
  },
  {
    title: "Can I contest a Will?",
    text: "People have freedom to leave their estate as they wish. But section 41 of the Queensland Succession Act 1981 allows eligible persons seek further provision. If you've been left out of a Will, or have been unfairly treated in terms of the amount of your inheritance you may be able to make a claim against the estate.",
  },
  {
    title: "How is a claim resolved?",
    text: "If you wish to make a claim you must demonstrate prove two things: that you did not receive 'adequate provision' for your 'proper maintenance and support.' Significant factors include the size of the estate; the financial position of the applicant and other beneficiaries; the relationship between the applicant and the deceased. Executors and claimants generally reach agreement through mediation and few cases actually go to Court.",
  },
  {
    title: "What if I don't believe that the Will was valid?",
    text: "You can challenge a Will if you believe that the will is a forgery or if the person lacked the mental capacity to make a Will. You can also challenge a Will if you believe that undue influence was brought to bear upon the deceased or if there was fraud involved.",
  },
  {
    title: "How do I make a claim?",
    text: "First, contact a lawyer, who can assess your claim and discuss the particular circumstances of your claim. If it's worth continuing, we will contact the executors and notify them of your claim. We will then gather evidence, prepare documents and make an offer to the executors. Many claims are settled through negotiation at this stage. If the matter isn't resolved, then we can lodge documents with the court to initiate proceedings. We can still negotiate and in some cases mediation will be required by the court. Failing all else, we will proceed to a court hearing wherein the evidence will be presented and the judge will make a decision.",
  },
  {
    title: "Do I need a lawyer? ",
    text: "Estates vary in complexity and Executor's duties can be complicated, so it may be a good idea to get advice from a lawyer. The cost of legal advice is usually covered by the estate, not the Executors.",
  },
  {
    title: "What if there is no Will?",
    text: "If the deceased did not have a Will, this situation is referred to as intestacy. The Succession Act set out how assets will be shared out after debts have been paid. If you are the next of kin you can apply for Letters of Administration, which will give you authority to finalise the estate, similar to Probate.",
  },
  {
    title: "How can we help?",
    text: "At every stage of contesting or challenging a Will it's important to have sound, experienced legal advice. We have the skills to negotiate on your behalf to avoid costly court fees, but if it comes down to court we also have the skills to fight on your behalf.",
  },
];

const About = () => {
  return (
    <Box>
      <TitleBlock />
      <ColouredTextStrip
        title="Wills, EPA & Estates"
        text="Nobody likes to think about it too much but you will inevitably have to leave your business or farm one day, whether by selling up, retiring or leaving for health reasons. In the Scenic Rim generally, and especially the Fassifern, we have many families who have lived and farmed in this area for generations. We understand the desire to pass on a family farm or homes to the next generation."
      />
      <Planning />
      <AccordionBlock
        title="Common Questions"
        accordionItems={commonQuestions}
      />
    </Box>
  );
};

export default About;
