import React from "react";
import TitleSlider from "../../components/TitleSlider";
import WhatWeDo from "../../components/WhatWeDo";
import ImageCards from "../../components/ImageCards";
import ColouredTextStrip from "../../components/ColouredTextStrip";

const Home = () => {
  return (
    <>
      <TitleSlider />
      <WhatWeDo />
      <ColouredTextStrip
        title="A local legal practice"
        text="We provide the community with a wide range of legal services, including
        conveyancing, wills and estate planning."
      />
      <ImageCards />
    </>
  );
};

export default Home;
