import React from "react";
import { Box } from "@mui/material";

const Map = () => {
  return (
    <Box>
      <div className="google-map-code">
        <iframe
          title="locationMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2962.361305941418!2d152.67985794362852!3d-27.998144425872763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b96d7d454aa6987%3A0xc62278faa74bff3a!2sBoylson%20%26%20Collyer!5e0!3m2!1sen!2sau!4v1671265580836!5m2!1sen!2sau"
          width="100%"
          height="450px"
          style={{
            boxShadow: 10,
            border: 1,
          }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </Box>
  );
};

export default Map;
