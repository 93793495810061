import * as React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Drawer,
  useTheme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";

export default function Navbar() {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 700px)");

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const listItems = [
    {
      text: "Home",
      to: "/home",
    },
    {
      text: "About",
      to: "/about",
    },
    {
      text: "Estates",
      to: "/estates",
    },
    {
      text: "Property",
      to: "/property",
    },
    {
      text: "Contact Us",
      to: "/contact",
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{ width: "280px" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {listItems.map((item) => (
          <ListItem>
            <ListItemButton>
              <Link
                href={item.to}
                sx={{
                  flexGrow: 1,
                  fontFamily: ["Inter", "sans-serif"].join(","),
                  fontSize: 26,
                  fontWeight: "bold",
                  color: "primary",
                }}
                underline="none">
                {item.text}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      elevation={10}
      sx={{
        background: "primary",
      }}>
      <Toolbar
        sx={{
          py: "1rem",
          justifyContent: "space-between",
        }}>
        {isNonMobile ? null : (
          <React.Fragment>
            <MenuIcon
              sx={{ mr: "0.7rem" }}
              onClick={toggleDrawer("left", true)}
            />
            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}>
              {list("left")}
            </Drawer>
          </React.Fragment>
        )}
        <Link
          href="/home"
          sx={{
            flexGrow: 1,
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 24,
            fontWeight: "bold",
            color: theme.palette.grey[200],
          }}
          underline="none">
          Boylson & Collyer
        </Link>
        {isNonMobile ? (
          <>
            <Link
              sx={{
                px: "0.5rem",
              }}
              href="/home"
              color="inherit"
              underline="none">
              HOME
            </Link>
            <Link
              sx={{
                px: "0.5rem",
              }}
              href="/about"
              color="inherit"
              underline="none">
              ABOUT
            </Link>
            <Link
              sx={{
                px: "0.5rem",
              }}
              href="/estates"
              color="inherit"
              underline="none">
              ESTATES
            </Link>
            <Link
              sx={{
                px: "0.5rem",
              }}
              href="/property"
              color="inherit"
              underline="none">
              PROPERTY
            </Link>
            <Link
              sx={{
                px: "0.5rem",
              }}
              href="/contact"
              color="inherit"
              underline="none">
              CONTACT US
            </Link>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
