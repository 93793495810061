import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const OurPeople = () => {
  const isNonMobile = useMediaQuery("(min-width: 700px)");
  return (
    <Box
      sx={{
        px: isNonMobile ? "5rem" : "1rem",
      }}>
      <Typography variant="h2">David Boylson (retired, consultant)</Typography>
      <Typography align="justify">
        David was born and raised in Forbes, western NSW, the son of a
        solicitor, and worked in many rural and Sydney firms. He and his family
        moved to New Guinea where he worked as a government solicitor. He later
        decided to return to Australia for his children’s education and he
        bought a law practice in Boonah. The practice was founded by fellow New
        Guinea ex-pat, Brian Grimsey who had sadly passed away soon after
        opening the firm. David grew the practice over a long career and is
        highly regarded in the areas of commercial, estate planning and leases.
        In his spare time David is a champion swimmer and enjoys bushwalking.
      </Typography>
      <Divider sx={{ m: "2rem" }} />
      <Typography variant="h2">Angela Collyer</Typography>
      <Typography align="justify">
        Angela was born in Ipswich of a railway family. She graduated from
        University of Queensland, became a teacher and moved to Boonah soon
        afterwards. Her four children all graduated from All Saints' Catholic
        Primary school and Boonah State High School. Three also graduated from
        UQ. While her children were young, Angela completed a Master of Arts
        (Local History) from UQ, wrote a number of local history books and also
        worked as a journalist for the local newspaper. In 1994 she began work
        as a TAFE teacher. While teaching, Angela completed a Juris Doctor law
        degree from UQ in 2008. She began working for David Boylson solicitor in
        Boonah in 2010 and was admitted as a solicitor in March 2011. The firm
        became Boylson & Collyer in 2014. Angela enjoys using her knowledge of
        local history when dealing with modern property law, conveyancing,
        leasing, wills and estates. In her spare time Angela enjoys bushwalking
        and gardening.
      </Typography>
      <Divider sx={{ m: "2rem" }} />
      <Typography variant="h2">
        Brooke Tomlinson and Janine Christensen
      </Typography>
      <Typography align="justify">
        Brooke Tomlinson and Janine Christensen are the conveyancing clerks who
        handle the day-to-day management of conveyancing. They each have twenty
        years' experience with a particular focus on conveyancing. They were
        both born and raised in Boonah and their extended families also live
        here. They are both married with children and are involved in the local
        community.
      </Typography>
      <Divider sx={{ m: "2rem" }} />
    </Box>
  );
};

export default OurPeople;
