import React from "react";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";

const AccordionBlock = (props) => {
  const isNonMobile = useMediaQuery("(min-width: 700px)");

  return (
    <Box
      sx={{
        m: isNonMobile ? "5rem" : "1rem",
      }}>
      <Typography variant="h1" align="center">
        {props.title}
      </Typography>
      {props.accordionItems.map((accordionItem, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{accordionItem.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{accordionItem.text}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AccordionBlock;
